import React from "react";
import { PageLayout } from "@components";
import Margins from "@components/Margins/Margins";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { ReportsHero } from "./_components/_ReportsHero/_ReportsHero";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./reports.module.scss";
import { RoiV2 } from "@components/RoiV2/RoiV2";
import { FaqV2 } from "@components/FAQV2/FaqV2";
import LabConsumablesCards from "pages/products/lab-consumables/_components/LabConsumablesCards";

import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";

// import LabConsumables from '../lab-consumables/index';

const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_2707)">
					<path
						d="M19 2.99976H9C7.89543 2.99976 7 3.89519 7 4.99976V14.9998C7 16.1043 7.89543 16.9998 9 16.9998H19C20.1046 16.9998 21 16.1043 21 14.9998V4.99976C21 3.89519 20.1046 2.99976 19 2.99976Z"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17 16.9998V18.9998C17 19.5302 16.7893 20.0389 16.4142 20.414C16.0391 20.789 15.5304 20.9998 15 20.9998H5C4.46957 20.9998 3.96086 20.789 3.58579 20.414C3.21071 20.0389 3 19.5302 3 18.9998V8.99976C3 8.46932 3.21071 7.96061 3.58579 7.58554C3.96086 7.21047 4.46957 6.99976 5 6.99976H7"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_2707">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "A source of truth for your inventory",
		description:
			"From reagents to custom item types - we customize to match exactly how it is in the lab.",
		to: "INVENTORY",
		Cta: "Virtual freezers",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_3509)">
					<path
						d="M12 9.99976C12 8.40846 11.3679 6.88233 10.2426 5.75712C9.11742 4.6319 7.5913 3.99976 6 3.99976H3V5.99976C3 7.59105 3.63214 9.11718 4.75736 10.2424C5.88258 11.3676 7.4087 11.9998 9 11.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 13.9998C12 12.4085 12.6321 10.8823 13.7574 9.75712C14.8826 8.6319 16.4087 7.99976 18 7.99976H21V8.99976C21 10.5911 20.3679 12.1172 19.2426 13.2424C18.1174 14.3676 16.5913 14.9998 15 14.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 19.9998V9.99976"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_3509">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 1.99976C12.5917 1.99976 13.1713 2.05114 13.7348 2.14969M12 1.99976C6.47715 1.99976 2 6.47691 2 11.9998C2 17.5226 6.47715 21.9998 12 21.9998M12 1.99976V21.9998M17.738 3.80876C18.6922 4.47845 19.5241 5.31065 20.1934 6.26516M21.8501 10.2654C21.9486 10.8287 22 11.4082 22 11.9998C22 12.5913 21.9486 13.1708 21.8501 13.7341M20.1892 17.7404C19.5203 18.6927 18.6896 19.5231 17.7369 20.1915M13.7328 21.8502C13.17 21.9485 12.591 21.9998 12 21.9998"
					stroke="#2C52F7"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View Pricing",
	},
];

const cards: FeatureCardProps[] = [
	{
		title: "Comprehensive reporting made easy",
		image: (
			<StaticImage
				src={"./_assets/live-editing-file.png"}
				alt="Comprehensive reporting made easy"
			/>
		),
		list: [
			"Export experiments straight from the main page, keeping your data safe",
			"Customize your report by selecting the projects that matter to you",
			"Save time and energy by combining project selection and data export in a single platform",
		],
		orientation: "right",
	},
	{
		title: "Pick and preview your way",
		image: (
			<StaticImage
				src={"./_assets/add-files-to-report.png"}
				alt="Pick and preview your way"
			/>
		),
		list: [
			"Our advanced features let you handpick individual experiments for your report",
			"Simply click on the experiment’s name to get a detailed preview before finalizing your selections",
			"Adjust every detail as needed, giving you control and transparency",
		],
	},
	{
		title: "PDFs made simple",
		image: (
			<StaticImage
				src={"./_assets/protocol-preview.png"}
				alt="PDFs made simple"
			/>
		),
		list: [
			"Convert your meticulous reports into shareable PDF formats, perfect for offline access",
			"Keep all your reports safe and easy to retrieve in a dedicated repository",
			"Make the most of this organized record-keeping feature by simplifying audits and historical data checks",
		],
		orientation: "right",
	},
];

export interface FaqProps {
	question: string;
	answer: string | any;
}

const faqItems: FaqProps[] = [
	{
		question: "What is an Electronic Lab Notebook (ELN)?",
		answer: "An Electronic Lab Notebook (ELN) is a modern digital tool designed to replace the traditional paper lab notebooks or basic spreadsheets that many scientists are used to. It’s widely adopted by researchers in various fields, especially in the life sciences. ELNs offer a range of helpful benefits, and when paired with Laboratory Information Management Systems (LIMS), these advantages become even more powerful.",
	},

	{
		question: "Can I export experiments into PDF files?",
		answer: "Yes, you can export experiments as PDF files using Genemod’s report feature.",
	},
	{
		question: "Can I combine multiple experiments into one PDF report?",
		answer: "Yes, you can include multiple experiments in a single report when exporting it as a PDF file.",
	},
	{
		question: "Can I keep the reports on Genemod without downloading them?",
		answer: "Yes, you can store and preview the generated reports directly on the Genemod platform without needing to download them.",
	},
];

export default function LiveEditing() {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "reports" }}>
			<Margins className={styles.roibg}>
				<ReportsHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<RoiV2 />
				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.joinInstitutions}
					/>
				</div>
				<LabConsumablesCards cards={labConsumablesCards} />
				<div className={styles.faqbg}>
					<FaqV2 faqItems={faqItems} />
				</div>
			</Margins>
		</PageLayout>
	);
}
